import React, { Suspense, useEffect, useState, lazy } from "react";

import "./App.css";
// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

const FurnitureShopPage = lazy(() => import("./Pages/Home/FurnitureShop"));

// Header
const TransparentHeaderPage = lazy(() =>
  import("./Pages/Header/TransparentHeaderPage")
);

const HeaderReverseScroll = lazy(() =>
  import("./Pages/Header/HeaderTypes/HeaderReverseScroll")
);
const MobileMenuClassicPage = lazy(() =>
  import("./Pages/Header/MobileMenu/MobileMenuClassicPage")
);
const MobileMenuModernPage = lazy(() =>
  import("./Pages/Header/MobileMenu/MobileMenuModernPage")
);
const MobileMenuFullScreen = lazy(() =>
  import("./Pages/Header/MobileMenu/MobileMenuFullScreen")
);
const HamburgerMenuPage = lazy(() =>
  import("./Pages/Header/HamburgerMenu/HamburgerMenuPage")
);
// Footer
const FooterStyle01Page = lazy(() =>
  import("./Pages/Footer/FooterStyle01Page")
);
const FooterStyle02Page = lazy(() =>
  import("./Pages/Footer/FooterStyle02Page")
);
const FooterStyle03Page = lazy(() =>
  import("./Pages/Footer/FooterStyle03Page")
);
const FooterStyle04Page = lazy(() =>
  import("./Pages/Footer/FooterStyle04Page")
);
const FooterStyle05Page = lazy(() =>
  import("./Pages/Footer/FooterStyle05Page")
);
const FooterStyle06Page = lazy(() =>
  import("./Pages/Footer/FooterStyle06Page")
);
const FooterStyle07Page = lazy(() =>
  import("./Pages/Footer/FooterStyle07Page")
);
const FooterStyle08Page = lazy(() =>
  import("./Pages/Footer/FooterStyle08Page")
);
const FooterStyle09Page = lazy(() =>
  import("./Pages/Footer/FooterStyle09Page")
);
const FooterStyle10Page = lazy(() =>
  import("./Pages/Footer/FooterStyle10Page")
);
const FooterStyle11Page = lazy(() =>
  import("./Pages/Footer/FooterStyle11Page")
);
const FooterStyle12Page = lazy(() =>
  import("./Pages/Footer/FooterStyle12Page")
);

// About Pages
const AboutMePage = lazy(() => import("./Pages/About/AboutMePage"));
const AboutUsPage = lazy(() => import("./Pages/About/AboutUsPage"));
const OurStoryPage = lazy(() => import("./Pages/About/OurStoryPage"));
const WhoWeArePage = lazy(() => import("./Pages/About/WhoWeArePage"));

// Services Pages
const OurServicesPage = lazy(() => import("./Pages/Services/OurServicesPage"));
const WhatWeOfferPage = lazy(() => import("./Pages/Services/WhatWeOfferPage"));
const OurProcessPage = lazy(() => import("./Pages/Services/OurProcessPage"));

// Contact Pages
const ContactUsSimplePage = lazy(() =>
  import("./Pages/Contact/ContactUsSimplePage")
);
const ContactUsClassicPage = lazy(() =>
  import("./Pages/Contact/ContactUsClassicPage")
);
const ContactUsModernPage = lazy(() =>
  import("./Pages/Contact/ContactUsModernPage")
);

// Additional Pages
const LatestNewsPage = lazy(() =>
  import("./Pages/AdditionalPages/LatestNewsPage")
);
const OurTeamPage = lazy(() => import("./Pages/AdditionalPages/OurTeamPage"));
const PricingPackagesPage = lazy(() =>
  import("./Pages/AdditionalPages/PricingPackagesPage")
);
const NotFoundPage = lazy(() => import("./Pages/404"));
const MaintenancePage = lazy(() =>
  import("./Pages/AdditionalPages/MaintenancePage")
);
const ComingSoonPage = lazy(() =>
  import("./Pages/AdditionalPages/ComingSoonPage")
);
const ComingSoonV2Page = lazy(() =>
  import("./Pages/AdditionalPages/ComingSoonV2Page")
);
const FaqSPage = lazy(() => import("./Pages/AdditionalPages/FaqSPage"));
const SearchResultPage = lazy(() =>
  import("./Pages/AdditionalPages/SearchResultPage")
);

// Shop Pages
const ShopWidePage = lazy(() => import("./Pages/Shop/ShopWide"));
const HomeBlogMetroPage = lazy(() => import("./Pages/Home/BlogMetro"));
const ShoppingCart = lazy(() => import("./Pages/Shop/ShoppingCart"));
const Checkout = lazy(() => import("./Pages/Shop/Checkout"));
const LoginRegister = lazy(() => import("./Pages/Shop/LoginRegister"));
const SingleProduct = lazy(() => import("./Pages/Shop/SingleProduct"));
const OnlyCategoriesPage = lazy(() => import("./Pages/Shop/OnlyCategories"));
const RightSidebar = lazy(() => import("./Pages/Shop/RightSidebar"));
const LeftSidebar = lazy(() => import("./Pages/Shop/LeftSideBar"));
const Footer = lazy(() => import("./Pages/Footer"));
const Privacy = lazy(() => import("./Pages/Privacy"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {/* Headers */}
                  <Route path="headers">
                    <Route
                      path="transparent-header"
                      element={
                        <TransparentHeaderPage
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="header-reverse-scroll"
                      element={<HeaderReverseScroll />}
                    />{" "}
                    <Route
                      path="mobile-menu-classic"
                      element={<MobileMenuClassicPage />}
                    />{" "}
                    <Route
                      path="mobile-menu-modern"
                      element={<MobileMenuModernPage />}
                    />
                    <Route
                      path="mobile-menu-full-screen"
                      element={<MobileMenuFullScreen />}
                    />{" "}
                    <Route
                      path="hamburger-menu"
                      element={<HamburgerMenuPage />}
                    />
                  </Route>

                  {/* Footers */}
                  <Route
                    path="footers"
                    element={<Footer style={{ "--base-color": "#0038e3" }} />}
                  >
                    <Route
                      path="footer-style-01"
                      element={
                        <FooterStyle01Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-02"
                      element={
                        <FooterStyle02Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-03"
                      element={
                        <FooterStyle03Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-04"
                      element={
                        <FooterStyle04Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-05"
                      element={
                        <FooterStyle05Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-06"
                      element={
                        <FooterStyle06Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-07"
                      element={
                        <FooterStyle07Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-08"
                      element={
                        <FooterStyle08Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-09"
                      element={
                        <FooterStyle09Page style={{ "--base-color": "#fff" }} />
                      }
                    />
                    <Route
                      path="footer-style-10"
                      element={
                        <FooterStyle10Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-10"
                      element={
                        <FooterStyle10Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-11"
                      element={
                        <FooterStyle11Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-12"
                      element={
                        <FooterStyle12Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                  </Route>

                  {/* Home Other */}
                  <Route
                    path="/"
                    element={
                      <FurnitureShopPage
                        style={{ "--base-color": "#ff6437" }}
                      />
                    }
                  />

                  {/*About Pages */}
                  <Route
                    path="/page/about-me"
                    element={
                      <AboutMePage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/about-us"
                    element={
                      <AboutUsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/our-story"
                    element={
                      <OurStoryPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/who-we-are"
                    element={
                      <WhoWeArePage style={{ "--base-color": "#0038e3" }} />
                    }
                  />

                  {/* Services Pages */}
                  <Route
                    path="/page/our-services"
                    element={
                      <OurServicesPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/what-we-offer"
                    element={
                      <WhatWeOfferPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/our-process"
                    element={
                      <OurProcessPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />

                  {/* Contact Pages */}
                  <Route
                    path="/page/contact-simple"
                    element={
                      <ContactUsSimplePage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/page/contact-classic"
                    element={
                      <ContactUsClassicPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/page/contact-modern"
                    element={
                      <ContactUsModernPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />

                  {/* Additional Pages */}
                  <Route
                    path="/page/our-team"
                    element={
                      <OurTeamPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/latest-news"
                    element={
                      <LatestNewsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/pricing-packages"
                    element={
                      <PricingPackagesPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/page/error-404"
                    element={
                      <NotFoundPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/maintenance"
                    element={<MaintenancePage />}
                  />
                  <Route
                    path="/page/coming-soon"
                    element={<ComingSoonPage />}
                  />
                  <Route
                    path="/page/coming-soon-V2"
                    element={<ComingSoonV2Page />}
                  />
                  <Route
                    path="/page/faq-s"
                    element={<FaqSPage style={{ "--base-color": "#0038e3" }} />}
                  />
                  <Route
                    path="/page/search-result"
                    element={
                      <SearchResultPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                  <Route
                    path="/privacy"
                    element={<Privacy style={{ "--base-color": "#0038e3" }} />}
                  />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
